function getFileName(value){
    if(value == null) return null
    return value.replace(/^.*\\/, "");
}

const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}


function generateVideoId(value){
    if(value == null) return null

    if(value.toLowerCase().includes('?v=')){
        return `${getParameterByName('v',value)}`
    }else if(value.toLowerCase().includes('youtu.be/')){
        return `${value.split('youtu.be/')[1]}`
    }
    return null
}
export {
    getFileName,
    formatBytes,
    generateVideoId
}