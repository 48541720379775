<template>
   <div class="y-inputField">
        <div class="y-labelText" v-if="label">
            {{label}}
        </div>
        <div >
          <div class="r-isRelative r-isExpand r-center-flex r-mt--10 r-pr-10">
            <div class="r-center-flex r-mtb-10" v-if="fileData.url != null">
                <div class="r-center-flex r-150-wd-max" >
                    <img :src="fileData.url" alt="upload image"  class="r-isExpand r-wd-600-max" v-if="fileData.isImage">
                    <video :src="fileData.url" alt="upload video"  class="r-isExpand r-wd-600-max" v-else-if="fileData.isVideo" controls></video>
                    <a :href="fileData.url" v-else target="_blank">
                      <ButtonCustom type='outline' >Download</ButtonCustom>
                    </a>
                </div>
                <div class="r-ml-10">
                    <p class="r-isBold r-14-fz">{{fileData.name}}</p>
                    <p class="r-12-fz r-black-40-text" >{{formatBytes(fileData.size)}}</p>
                </div>
            </div>
          </div>
          <ButtonCustom class="r-attach-button r-isExpand r-isRelative" padding="4" color="warning">
              <input id="fileData" ref="fileData" class="r-hide-input" type="file" @change="importData('fileData')" :accept="accept" @click.stop="resetError()">
              <template v-if="fileData.url == null">
                  Attach
              </template>
              <template v-else>
                  Update
              </template>
          </ButtonCustom>
        </div>
        <slot name="topErrorText"></slot>
        <div class="y-errorText y-red-text" :style="error ? 'opacity: 1 !important;': ''" v-if="withError">
            {{errorMsg}}
        </div>
        <slot name="bottomErrorText"></slot>
    </div>
</template>
<script>
import { formatBytes } from '@/common/helper'
import { imageRegex, videoRegex } from '@/common/regex'
import ButtonCustom from '@/components/Helper/Button'

export default {
  emits: ['setFileData','resetError','setError'],
  components: {
    ButtonCustom
  },
  props: {
    label: {
      default: null
    },
    error: {
      default: false
    },
    errorMsg: {
      default: 'Tidak Boleh Kosong'
    },
    withError: {
      default: true
    },
    accept: {
      default: '.*'
    },
    fileData: {
      default: () => ({
        url: null,
        name: null,
        size: null,
        isImage: false,
        isVideo: false,
        file: null
      })
    }
  },
  methods: {
    formatBytes(value){
      if(!value) return '-'
      return formatBytes(value)
    },
    resetError(){
      this.$emit('resetError')
    },
    importData(id) {
        const fileInput = this.$refs[id];
        const fileUpload = fileInput.files[0];
        const fsize = fileUpload.size;
        const file = Math.round((fsize / 1024)); 
        if (file <= 258060) { 
            const objectUrl = URL.createObjectURL(fileUpload);
            const fileData = {
                url: objectUrl,
                name: fileUpload.name,
                size: fsize,
                file: fileUpload,
                isImage: imageRegex.exec(fileUpload.name),
                isVideo: videoRegex.exec(fileUpload.name),
            }
            this.$emit('setFileData',fileData)
        }else{
          this.$emit('setError', 'File tidak boleh lebih dari 255MB')
        }
    },
  }
}
</script>